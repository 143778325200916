import { useCallback, useContext } from "react";
import { AuthContext } from "../../../App";
import { SERVER_HOST } from "../../../constants/config";

const BaseAPIUrl = SERVER_HOST;

const useHologramDetectionService = () => {
  const { authState } = useContext(AuthContext);

  const detectHologram = useCallback(
    async (params) => {
      const config = {
        headers: {
          Authorization: "Bearer " + authState.token,
        },
        method: "post",
        body: params,
      };

      return fetch(BaseAPIUrl + "/hologramDetection/detect-hologram", config).then((response) => response.json());
    },
    [authState]
  );

  return {
    detectHologram
  };
};

export { useHologramDetectionService };
