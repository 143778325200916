import React from "react";
import { Heading, Text, Button, Box, Card } from "@radix-ui/themes";
import { Check, TriangleAlert } from "lucide-react";
import passportGood from "../../../assets/images/passportguideline-good.png";
import passportGood1 from "../../../assets/images/passportguideline-good-1.png";
import passportBad from "../../../assets/images/passportguideline-wrong.png";
import passportBad1 from "../../../assets/images/passportguideline-wrong-1.png";

const Guide = ({ onNext }) => {
  return (
    <div className="min-h-screen p-4 flex items-center justify-center">
      <Card className="w-full max-w-md">
        <Box className="text-center mb-4">
          <Heading size="5">Passport Photo Guidelines</Heading>
          <Text size={"2"}>Follow these instructions to capture your passport photo</Text>
        </Box>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="flex flex-col items-center justify-center">
            <h4 className="font-medium text-green-600 mb-2">Good</h4>
            <div className="flex space-x-4">
              <img
                src={passportGood}
                alt="Correct passport photo example"
                width={80}
                height={"auto"}
                className="rounded-lg shadow-md"
              />

              <img
                src={passportGood1}
                alt="Correct passport photo example 1"
                width={80}
                height={"auto"}
                className="rounded-lg shadow-md object-contain bg-black"
              />
            </div>
          </div>
          <div className="flex flex-col items-center justify-center">
            <h4 className="font-medium text-red-600 mb-2">Bad</h4>
            <div className="flex space-x-4">
              <img
                src={passportBad}
                alt="Incorrect passport photo example"
                width={80}
                height={"auto"}
                className="rounded-lg shadow-md"
              />
              <img
                src={passportBad1}
                alt="Incorrect passport photo example 1"
                width={80}
                height={"auto"}
                className="rounded-lg shadow-md object-contain bg-black"
              />
            </div>
          </div>
        </div>

        <div className="bg-white shadow-lg rounded-lg overflow-hidden my-8">
          <div className="px-4 py-2 bg-blue-600">
            <h4 className="font-semibold text-white">Important Guidelines:</h4>
          </div>
          <div className="p-4 space-y-4">
            {[
              { icon: Check, text: "Ensure good lighting conditions" },
              { icon: Check, text: "Make sure all text is clearly visible" },
              { icon: Check, text: "Avoid glare or reflections on the passport" },
              { icon: Check, text: "Ensure the passport fills most of the frame in the camera view" },
            ].map((item, index) => (
              <div key={index} className="flex items-center space-x-3">
                <item.icon className="w-6 h-6 text-green-600" />
                <span>{item.text}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
          <div className="px-4 py-2 bg-red-600">
            <h4 className="font-semibold text-white">DO NOT:</h4>
          </div>
          <div className="p-4 space-y-4">
            {[
              { icon: TriangleAlert, text: "Take blurry or unclear photos" },
              { icon: TriangleAlert, text: "Cover any part of the passport" },
              { icon: TriangleAlert, text: "Take photo at an angle" },
            ].map((item, index) => (
              <div key={index} className="flex items-center space-x-3">
                <item.icon className="w-6 h-6 text-red-600" />
                <span>{item.text}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="flex items-center justify-center">
          <Button className="w-full group" onClick={onNext}>
            I Understand, Continue to Camera
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default Guide;
