import React, { useContext, useState } from "react";
import { Heading, Text, Button, Flex, Box, Card } from "@radix-ui/themes";
import ImageProcessDialog from "./ImageProcessDialog";
import { EKYCPassportContext } from "../Passport";

const Upload = ({ isSubmitting, onSubmit }) => {
  const { id } = useContext(EKYCPassportContext);

  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [processingError, setProcessingError] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      setDialogVisible(true);
    }
  };

  const handleProcessSuccess = (transformedBase64) => {
    setPreviewUrl(`data:image/jpeg;base64,${transformedBase64}`);
    setDialogVisible(false);
    setProcessingError(null);
  };

  const handleProcessError = (error) => {
    setProcessingError(error);
    setDialogVisible(false);
    setSelectedImage(null);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
    setSelectedImage(null);
    setProcessingError(null);
  };

  return (
    <div className="min-h-screen p-4 flex items-center justify-center">
      <Card className="w-full max-w-md">
        <Box className="text-center mb-4">
          <Heading size="5"> Upload Passport Photo</Heading>
          <Text size={"2"}>Please upload a clear photo of your passport</Text>
        </Box>

        <Flex
          direction="column"
          align="center"
          gap="4"
          style={{
            border: "2px dashed var(--gray-6)",
            borderRadius: "8px",
            padding: "2rem",
            minHeight: "200px",
            cursor: "pointer",
          }}
          onClick={() => document.getElementById("fileInput").click()}
        >
          {previewUrl ? (
            <img
              src={previewUrl}
              alt="Preview"
              style={{
                maxWidth: "100%",
                maxHeight: "300px",
                borderRadius: "4px",
              }}
            />
          ) : (
            <>
              <Text size="5">📸</Text>
              <Text align="center">Click or drag and drop to upload your passport photo</Text>
              <Text size="2" color="gray">
                Supported formats: JPG, PNG
              </Text>
            </>
          )}
          <input
            type="file"
            id="fileInput"
            accept="image/*"
            disabled={isSubmitting}
            onChange={handleImageChange}
            style={{ display: "none" }}
          />
        </Flex>

        {processingError && (
          <Box>
            <Text color="red" size="2" style={{ marginTop: "8px" }}>
              {processingError}
            </Text>
          </Box>
        )}

        <Button
          className="w-full group"
          style={{ marginTop: "8px" }}
          onClick={onSubmit}
          disabled={!selectedImage || !previewUrl || isSubmitting}
          loading={isSubmitting}
        >
          Submit
        </Button>

        <ImageProcessDialog
          id={id}
          visible={dialogVisible}
          uploadFile={selectedImage}
          sourceOrigin="web"
          onSuccess={handleProcessSuccess}
          onError={handleProcessError}
          onClose={handleDialogClose}
        />
      </Card>
    </div>
  );
};

export default Upload;
