import "./App.css";
import "@radix-ui/themes/styles.css";
import { Theme } from "@radix-ui/themes";
import React, { useState } from "react";
import ThemeRoutes from "./routes";
import { BrowserRouter } from "react-router-dom";
import { Amplify } from "aws-amplify";
import awsexports from "./aws-exports";

Amplify.configure(awsexports);

export const AuthContext = React.createContext();

function App() {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    token: null,
  });

  return (
    <AuthContext.Provider
      value={{
        authState,
        setAuthState,
      }}
    >
      <Theme accentColor="jade">
        <BrowserRouter>
          <ThemeRoutes />
        </BrowserRouter>
      </Theme>
    </AuthContext.Provider>
  );
}

export default App;
