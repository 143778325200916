import React, { useRef, useState, useContext, useEffect } from "react";
import { Box, Typography, Stack, ButtonBase, Backdrop, CircularProgress, Grid, Button, Divider } from "@mui/material";
import { PhotoCamera as PhotoCameraIcon, Cancel as CancelIcon } from "@mui/icons-material";
import { EKYCContext } from "../EKYC3";
import { useHologramDetectionService } from "../services/useHologramDetectionService";

const VerifyHologram = (props) => {
  const { onNext } = props;

  const documentImageInputRef = useRef(null);
  const { id, detectHologramResult, setDetectHologramResult } = useContext(EKYCContext);

  const { detectHologram } = useHologramDetectionService();

  const [documentImageBase64, setDocumentImageBase64] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [submittingMessage, setSubmittingMessage] = useState("Uploading...");
  const [failVerification, setFailVerification] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [possibleFailReasonList, setPossibleFailReasonList] = useState([]);

  const onSubmit = async () => {
    setSubmitting(true);
    setSubmittingMessage("Verifying...");

    try {
      if (!id) {
        setSubmitting(false);
        setFailVerification(true);
        setErrorMessage("Session ID is missing. Please start again from the beginning.");
        return;
      }

      // Convert base64 to file
      const byteCharacters = atob(documentImageBase64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/jpeg" });

      // Create FormData and append file
      const formData = new FormData();
      formData.append("id", id);
      formData.append("file", blob, "document.jpg");

      const response = await detectHologram(formData);

      if (response.status === "success") {
        setFailVerification(false);
        setDetectHologramResult(response.data);
        onNext();
      } else {
        setFailVerification(true);
        setErrorMessage(response.message);
        setPossibleFailReasonList(response.data?.FailReasonAndSolutionList || []);
        setDetectHologramResult(response.data);
      }
      setSubmitting(false);
    } catch (error) {
      console.error("Verification error:", error);
      setSubmitting(false);
      setFailVerification(true);
      setErrorMessage("An error occurred during verification. Please try again.");
    }
  };

  return (
    <Box>
      <Backdrop
        open={submitting}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Stack alignItems={"center"} justifyContent={"center"}>
          <CircularProgress
            style={{
              color: "rgba(255, 255, 255)",
            }}
          />
          <Typography variant="h6" sx={{ color: "#fff" }}>
            {submittingMessage}
          </Typography>
        </Stack>
      </Backdrop>

      {failVerification ? (
        <Box p={2} sx={{
            maxWidth: {
              xs: "100%",
              sm: "80%",
              md: "70%",
              lg: "60%",
              xl: "50%"
            },
            margin: '0 auto', // Center horizontally
          }} >
            <Box textAlign={"center"}>
              <Box my={2}>
                <CancelIcon htmlColor="#F1612E" style={{ fontSize: 72 }} />
                <Typography fontWeight={"bold"} fontSize={24}>
                  {errorMessage}
                </Typography>
              </Box>
              <Typography variant="body1">Please refer to the details below for the solution.</Typography>
            </Box>
            <Box px={2} mt={4}>
              <Box textAlign={"center"} bgcolor={"#CFC7CA"} p={2}>
                <Box>
                  <Typography fontWeight="bold" my={2}>
                    Hologram Detection
                  </Typography>
  
                  <Box border={"2px solid #000"}>
                    <img
                      src={"data:image/jpeg;base64," + documentImageBase64}
                      alt="MyKad Front"
                      width={"100%"}
                      height={"auto"}
                    />
                  </Box>
                </Box>
                <Divider sx={{ marginTop: 4, marginBottom: 4 }} />
              </Box>
              <Grid container>
                <Grid item xs={6} border={"1px solid #000"} p={1}>
                  <Typography fontWeight="bold">Possible Reasons </Typography>
                </Grid>
                <Grid item xs={6} border={"1px solid #000"} borderLeft={"none"} p={1}>
                  <Typography fontWeight="bold">Solution </Typography>
                </Grid>
                {possibleFailReasonList.map((item, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={6} border={"1px solid #000"} p={1}>
                      {item.reason}
                    </Grid>
                    <Grid item xs={6} border={"1px solid #000"} borderLeft={"none"} p={1}>
                      {item.solution}
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
  
            <Box textAlign={"center"} mt={4}>
              <Button
                variant="contained"
                onClick={() => {
                  setDetectHologramResult(null);
                  setFailVerification(false);
                  setErrorMessage(null);
                }}
                sx={{
                  backgroundColor: "#B41E8E",
                  minWidth: "120px",
                  borderColor: "#000",
                }}
              >
                RETRY
              </Button>
            </Box>
          </Box>
      ) : (
        <Box p={2}
        sx={{
          maxWidth: {
            xs: "100%",
            sm: "80%",
            md: "70%",
            lg: "60%",
            xl: "50%"
          },
          margin: '0 auto', // Center horizontally
        }} >
          <Typography variant="h6" textAlign="center" gutterBottom>
            Upload an image of the front of your MyKad.
          </Typography>
          <Typography variant="body1" textAlign="center" gutterBottom>
            Ensure the hologram is fully visible on your MyKad.
          </Typography>

          <Box mt={4}>
            <ButtonBase
              style={{
                border: "1px solid #000",
                width: "100%",
                minHeight: "150px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "20px",
              }}
              onClick={() => documentImageInputRef.current.click()}
            >
              {documentImageBase64 ? (
                <img
                  src={"data:image/jpeg;base64," + documentImageBase64}
                  alt="Uploaded document"
                  style={{ width: "100%", height: "auto" }}
                />
              ) : (
                <>
                  <PhotoCameraIcon style={{ fontSize: 48, marginBottom: 8 }} />
                  <Typography>Click to upload an image</Typography>
                </>
              )}
            </ButtonBase>
            {documentImageBase64 && (
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    documentImageInputRef.current.click();
                  }}
                >
                  Retake
                </Button>
              </Box>
            )}

            <input
              ref={documentImageInputRef}
              accept="image/*"
              type="file"
              hidden
              onChange={(e) => {
                const file = e.target.files[0];
                if (file) {
                  const reader = new FileReader();
                  reader.onloadend = () => {
                    const base64 = reader.result.split(",")[1];
                    setDocumentImageBase64(base64);
                    setErrorMessage(null);
                  };
                  reader.readAsDataURL(file);
                }
              }}
            />
          </Box>

          {documentImageBase64 && (
            <Box textAlign="center" mt={2}>
              <Button
                variant="contained"
                onClick={onSubmit}
                sx={{
                  backgroundColor: "#B41E8E",
                  minWidth: "120px",
                }}
              >
                VERIFY
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default VerifyHologram;
