import React from "react";
import { Heading, Text, Button, Box, Card, Select } from "@radix-ui/themes";
import { Dock, Earth, User } from "lucide-react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object } from "yup";

const schema = object().shape({
  name: string()
    .matches(/^[a-zA-Z\s\-\.\'\/@]*$/, "Invalid name. No special characters allowed.")
    .required("Name is required."),
  idNumber: string()
    .required("Passport number is required")
    .matches(/^[A-Z0-9]{6,12}$/, "Invalid passport number format"),
  country: string().required("Country is required"),
});

// List of countries (you can expand this list as needed)
const countries = [
  { value: "MYS", label: "Malaysia" },
  { value: "IDN", label: "Indonesia" },
  { value: "SGP", label: "Singapore" },
  { value: "TH", label: "Thailand" },
  { value: "VN", label: "Vietnam" },
  { value: "PH", label: "Philippines" },
  { value: "MM", label: "Myanmar" },
  { value: "KH", label: "Cambodia" },
  { value: "LA", label: "Laos" },
  { value: "BRN", label: "Brunei" },
  { value: "TLS", label: "Timor-Leste" },
].sort((a, b) => a.label.localeCompare(b.label));

const EnterData = ({ onNext }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <div className="min-h-screen p-4 flex items-center justify-center">
      <Card className="w-full max-w-md">
        <Box className="text-center mb-4">
          <div className="flex items-center justify-center w-16 h-16 mx-auto mb-2 bg-primary/5 rounded-full">
            <Dock className="w-8 h-8 text-primary" />
          </div>
          <Heading size="4">Passport eKYC Verification</Heading>
          <Text>Please enter your details as shown in your passport</Text>
        </Box>

        <Box>
          <form onSubmit={handleSubmit(onNext)} className="space-y-4">
            <div className="space-y-2">
              <Text htmlFor="fullName" className="flex items-center gap-2">
                <User className="w-4 h-4" />
                Full Name (as per passport)
              </Text>
              <Box>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value }, fieldState: { error, invalid } }) => (
                    <>
                      <input
                        value={value}
                        type="text"
                        placeholder="Enter your full name as per passport"
                        style={{
                          width: "100%",
                          padding: "8px",
                          borderRadius: "4px",
                          border: "1px solid var(--gray-7)",
                        }}
                        onChange={(e) => {
                          // if e.target.value is special character, it will not be set to the input.
                          if (/^[a-zA-Z\s\-\.\'\/@]*$/.test(e.target.value)) {
                            onChange(e.target.value.toUpperCase());
                          }
                        }}
                      />
                      {error && (
                        <Text size="2" color="red" mt="1">
                          {error.message}
                        </Text>
                      )}
                    </>
                  )}
                />
              </Box>
            </div>
            <div className="space-y-2">
              <Text htmlFor="passportNumber" className="flex items-center gap-2">
                <Dock className="w-4 h-4" />
                Passport Number
              </Text>

              <Box>
                <Controller
                  name="idNumber"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value }, fieldState: { error, invalid } }) => (
                    <>
                      <input
                        value={value}
                        type="text"
                        placeholder="Enter passport number"
                        style={{
                          width: "100%",
                          padding: "8px",
                          borderRadius: "4px",
                          border: "1px solid var(--gray-7)",
                        }}
                        onChange={(e) => {
                          // allow numbers and alphabets
                          if (/^[a-zA-Z0-9]*$/.test(e.target.value)) {
                            onChange(e.target.value.toUpperCase());
                          }
                        }}
                      />
                      {error && (
                        <Text size="2" color="red" mt="1">
                          {error.message}
                        </Text>
                      )}
                    </>
                  )}
                />
              </Box>
            </div>
            <div className="space-y-2">
              <Text htmlFor="country" className="flex items-center gap-2">
                <Earth className="w-4 h-4" />
                Country of Issue
              </Text>
              <Controller
                name="country"
                control={control}
                render={({ field }) => (
                  <Select.Root onValueChange={field.onChange} value={field.value}>
                    <Select.Trigger
                      placeholder="Select country of issue"
                      style={{
                        width: "100%",
                        height: "42px",
                      }}
                    />
                    <Select.Content
                      position="popper"
                      style={{
                        width: "var(--radix-select-trigger-width)",
                        backgroundColor: "white",
                      }}
                    >
                      {countries.map((country) => (
                        <Select.Item
                          key={country.value}
                          value={country.value}
                          style={{
                            padding: "8px",
                          }}
                        >
                          {country.label}
                        </Select.Item>
                      ))}
                    </Select.Content>
                  </Select.Root>
                )}
              />
              {errors.country && (
                <Text size="2" color="red" mt="1">
                  {errors.country.message}
                </Text>
              )}
            </div>
            <Button type="submit" className="w-full group">
              Continue
            </Button>
          </form>
        </Box>
      </Card>
    </div>
  );
};

export default EnterData;
