import React from "react";
import { Button, Card } from "@radix-ui/themes";

const Success = ({ profilePhotoBase64, liveFacePhotoBase64, onStartAgain }) => {
  return (
    <div className="min-h-screen p-4 flex items-center justify-center">
      <Card className="w-full max-w-md">
        <div className="text-center">
          <h1 className="text-2xl font-semibold text-green-800">Verification Success</h1>
          <p className="text-gray-600 mt-2">Your passport has been verified successfully.</p>
        </div>

        <div className="flex justify-between my-6">
          <div className="w-[48%]">
            <img
              src={"data:image/jpeg;base64," + profilePhotoBase64}
              width={"auto"}
              height={200}
              className="rounded-lg object-contain w-full h-48"
            />
          </div>
          <div className="w-[48%]">
            <img
              src={"data:image/jpeg;base64," + liveFacePhotoBase64}
              width={"auto"}
              height={200}
              className="rounded-lg object-contain w-full h-48"
            />
          </div>
        </div>

        <div className="flex justify-center mt-6">
          <Button className="w-full" onClick={onStartAgain}>
            Start Again
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default Success;
