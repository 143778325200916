import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import ScanbotSDK from "scanbot-web-sdk";
import { usePassportService } from "../usePassportService";

const ImageProcessDialog = ({ visible, id, uploadFile, sourceOrigin, onSuccess, onError, onClose }) => {
  const [sdk, setSdk] = useState(null);

  const { upload } = usePassportService();

  useEffect(() => {
    if (visible) {
      const initializeSdk = async () => {
        const sdk = await ScanbotSDK.initialize({ licenseKey: "" });
        setSdk(sdk);
      };

      initializeSdk();
    }

    return () => {
      if (sdk) {
        setSdk(null);
      }
    };
  }, [visible]);

  useEffect(() => {
    if (sdk && uploadFile && visible) {
      const reader = new FileReader();

      reader.readAsArrayBuffer(uploadFile);

      reader.onload = async (e) => {
        const result = await sdk.detectDocument(reader.result);
        if (result.success === true) {
          if (result.detectionStatus === "OK_BadAngle") {
            onError("Please ensure the document is straight and try again.");
            return;
          }

          const cropped = await sdk.cropAndRotateImageCcw(reader.result, result.polygon, 0);

          // convert uint8array to blob
          const croppedFile = new Blob([cropped], { type: "image/jpeg" });

          const formData = new FormData();
          formData.append("id", id);
          formData.append("croppedImage", croppedFile);
          formData.append("originalImage", uploadFile);
          formData.append("sourceOrigin", sourceOrigin);

          let transformedBase64 = null;
          let res = await upload(formData);
          if (res.status === "success") {
            transformedBase64 = res.data.transformedBase64;
          } else {
            onError(
              res.message ? res.message : "Unable to detect document. Please put on a flat surface and try again."
            );
            return;
          }
          onSuccess(transformedBase64);
        } else {
          onError("Cannot detect document. Ensure the document is filling up most of the screen.");
          return;
        }
      };
    }
  }, [sdk, uploadFile, visible]);

  return (
    <Dialog open={visible} onClose={onClose}>
      <DialogContent>
        <Typography variant="body2">Uploading image...</Typography>
      </DialogContent>
      <LinearProgress />
    </Dialog>
  );
};

export default ImageProcessDialog;
