import React, { useState, useContext, useRef, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonBase from "@mui/material/ButtonBase";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import CancelIcon from "@mui/icons-material/Cancel";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { EKYCContext } from "../EKYC3";
import { AspectRatio } from "react-aspect-ratio";
import { useDocumentLivenessService } from "../services/useDocumentLivenessService";
import DocLivenessImage from "../../../assets/images/DocLivenessImage.jpeg";
import { set } from "react-hook-form";

const VerifyDocumentLiveness = (props) => {
  const { onNext } = props;

  const documentLivenessInputRef = useRef(null);

  const { id, docType, documentLivenessResult, setDocumentLivenessResult } = useContext(EKYCContext);

  const { verify } = useDocumentLivenessService();

  const [documentLivenessBase64, setDocumentLivenessBase64] = useState(null);

  const [submitting, setSubmitting] = useState(false);
  const [submittingMessage, setSubmittingMessage] = useState("Uploading...");
  const [failVerification, setFailVerification] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [possibleFailReasonList, setPossibleFailReasonList] = useState([]);

  const [isFlipped, setIsFlipped] = useState(false); 

  const [isMobile, setIsMobile] = useState(false);
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  const handleFlipImage = () => {
    if (documentLivenessBase64) {
      const img = new Image();
      img.src = `data:image/jpeg;base64,${documentLivenessBase64}`;
  
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
  
        // Set canvas dimensions
        canvas.width = img.width;
        canvas.height = img.height;
  
        // Apply horizontal flip transformation
        ctx.translate(canvas.width, 0);
        ctx.scale(-1, 1);
  
        // Draw the image onto the canvas
        ctx.drawImage(img, 0, 0);
  
        // Extract the flipped image as a Base64 string
        const flippedBase64 = canvas.toDataURL("image/jpeg").split(",")[1];
  
        // Update the state with the flipped image
        setDocumentLivenessBase64(flippedBase64);
      };
    }
  };
  

  useEffect(() => {
    const mobilePatterns = [/android/i, /iPad|iPhone|iPod/i, /windows phone/i, /blackberry/i, /opera mini/i, /mobile/i];
    const isMobileDevice = mobilePatterns.some((pattern) => pattern.test(userAgent));
    setIsMobile(isMobileDevice);
  }, []);

  const onSubmit = async () => {
    setSubmitting(true);
    setSubmittingMessage("Verifying...");

    try {
      
      if (!id) {
        setSubmitting(false);
        setFailVerification(true);
        setErrorMessage("Session ID is missing. Please start again from the beginning.");
        return;
      }

      // Convert base64 to file
      const byteCharacters = atob(documentLivenessBase64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'image/jpeg' });
      
      // Create FormData and append file
      const formData = new FormData();
      formData.append('id', id);
      formData.append('file', blob, 'document.jpg');

      const response = await verify(formData);

      if (response.status === "success") {
        setFailVerification(false);
        setDocumentLivenessResult(response.data);
        onNext();
      } else {
        setFailVerification(true);
        setErrorMessage(response.message);
        setPossibleFailReasonList(response.data?.FailReasonAndSolutionList || []);
        setDocumentLivenessResult(response.data);
      }
      setSubmitting(false);
    } catch (error) {
      console.error('Verification error:', error);
      setSubmitting(false);
      setFailVerification(true);
      setErrorMessage("An error occurred during verification. Please try again.");
    }
  };

  return (
    <Box display={"flex"} alignContent={"center"} justifyContent={"center"}>
      <Backdrop
        open={submitting}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Stack alignItems={"center"} justifyContent={"center"}>
          <CircularProgress
            style={{
              color: "rgba(255, 255, 255)",
            }}
          />
          <Typography variant="h6" sx={{ color: "#fff" }}>
            {submittingMessage}
          </Typography>
        </Stack>
      </Backdrop>

      {failVerification ? (
        <Box p={2} sx={{
          maxWidth: {
            xs: "100%",
            sm: "80%",
            md: "70%",
            lg: "60%",
            xl: "50%"
          },
          margin: "0 auto"
        }} >
          <Box textAlign={"center"}>
            <Box my={2}>
              <CancelIcon htmlColor="#F1612E" style={{ fontSize: 72 }} />
              <Typography fontWeight={"bold"} fontSize={24}>
                {errorMessage}
              </Typography>
            </Box>
            <Typography variant="body1">Please refer to the details below for the solution.</Typography>
          </Box>
          <Box px={2} mt={4}>
            <Box textAlign={"center"} bgcolor={"#CFC7CA"} p={2}>
              <Box>
                <Typography fontWeight="bold" my={2}>
                  Selfie with MyKad
                </Typography>

                <Box border={"2px solid #000"}>
                  <img
                    src={"data:image/jpeg;base64," + documentLivenessBase64}
                    alt="MyKad Front"
                    width={"100%"}
                    height={"auto"}
                  />
                </Box>
                <Typography my={2} mb={1}>
                  {documentLivenessResult?.["CustomerName"]}
                </Typography>
                <Typography>{documentLivenessResult?.["NRICFront"]}</Typography>
              </Box>
              <Divider sx={{ marginTop: 4, marginBottom: 4 }} />
            </Box>
            <Grid container>
              <Grid item xs={6} border={"1px solid #000"} p={1}>
                <Typography fontWeight="bold">Possible Reasons </Typography>
              </Grid>
              <Grid item xs={6} border={"1px solid #000"} borderLeft={"none"} p={1}>
                <Typography fontWeight="bold">Solution </Typography>
              </Grid>
              {possibleFailReasonList.map((item, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={6} border={"1px solid #000"} p={1}>
                    {item.reason}
                  </Grid>
                  <Grid item xs={6} border={"1px solid #000"} borderLeft={"none"} p={1}>
                    {item.solution}
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Box>

          <Box textAlign={"center"} mt={4}>
            <Button
              variant="contained"
              onClick={() => {
                setDocumentLivenessResult(null);
                setFailVerification(false);
                setErrorMessage(null);
              }}
              sx={{
                backgroundColor: "#B41E8E",
                minWidth: "120px",
                borderColor: "#000",
              }}
            >
              RETRY
            </Button>
          </Box>
        </Box>
      ) : (
        <Grid container justifyContent={"center"} p={4}>
          <Grid item xs={12} md={4}>
          {!documentLivenessBase64 && (
            <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} margin={2}>
              <Typography variant="h6" fontWeight={"bold"} textAlign={"center"} my={1}>
                Please take a picture with your IC just like the sample below.
              </Typography>
              <Box
                component="img"
                src={DocLivenessImage}
                alt="Sample of how to take a good picture of your IC"
                width={"60%"}
                height={"auto"}
                my={2}
              />
            </Box>
          )}
            <Stack spacing={2} mb={2}>
              <Box>
                {documentLivenessBase64 && (
                <Typography variant="h6" fontWeight={"bold"} textAlign={"center"} my={1}>
                  Please ensure your IC is fully visible and your image is oriented correctly.
                </Typography>
                )}
                <ButtonBase
                  style={{
                    border: "1px solid #B41E8E",
                  }}
                  sx={{ width: "100%" }}
                  onClick={() => {
                    documentLivenessInputRef.current.click();
                  }}
                >
                  <AspectRatio
                    ratio={"3/4"}
                    style={{
                      width: "100%",
                      minHeight: "180px",
                      height: "auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {documentLivenessBase64 ? (
                      <Box
                        component="img"
                        src={"data:image/jpeg;base64," + documentLivenessBase64}
                        alt="MyKad Front"
                        width={"100%"}
                        height={"auto"}
                      />
                    ) : (
                      <>
                        <PhotoCameraIcon fontSize={"large"} htmlColor="#B41E8E" />
                        <Typography variant="body1">Selfie with IC</Typography>
                      </>
                    )}
                    

                    <input
                      ref={documentLivenessInputRef}
                      accept="image/*"
                      id="mykad-front"
                      type="file"
                      capture="environment"
                      hidden
                      onChange={async (e) => {
                        const file = e.target.files[0];
                        if (file) {
                          const reader = new FileReader();
                          reader.onloadend = () => {
                            const base64String = reader.result.split(',')[1];
                            setDocumentLivenessBase64(base64String);
                          };
                          reader.readAsDataURL(file);
                        }
                      }}
                    />
                  </AspectRatio>
                </ButtonBase>

                {documentLivenessBase64 && (
                  <Box
                    sx={{
                      mt: 2,
                      display: "flex",
                      justifyContent: "center",
                      gap: 1,
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleFlipImage} // Flip image button
                    >
                      Flip Image
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        documentLivenessInputRef.current.click();
                      }}
                    >
                      Retake
                    </Button>
                  </Box>
                )}
              </Box>
            </Stack>

            <Box textAlign={"center"}>
              <LoadingButton
                sx={{
                  backgroundColor: "#B41E8E",
                  minWidth: "120px",
                  borderColor: "#000",
                }}
                variant="contained"
                onClick={onSubmit}
                loading={submitting}
                disabled={!documentLivenessBase64}
              >
                <span>CONFIRM</span>
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default VerifyDocumentLiveness;
