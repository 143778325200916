import React, { useState, useContext, useCallback } from "react";
import { Box, Button, Card, Heading, Text } from "@radix-ui/themes";
import { Camera } from "lucide-react";
import { FaceLivenessDetector } from "@aws-amplify/ui-react-liveness";
import { useFaceLivenessService } from "../../ekyc/services/useFaceLivenessService";
import { EKYCPassportContext } from "../Passport";

const Liveness = (props) => {
  const { onSuccess, onFail } = props;

  const { id } = useContext(EKYCPassportContext);

  const { createStreamSession, getLivenessSessionResult, comparePassportProfileWithLivePerson } =
    useFaceLivenessService();

  const [isStarted, setIsStarted] = useState(false);
  const [faceLivenessSessionId, setFaceLivenessSessionId] = useState(null);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const onFaceLivenessStart = async () => {
    setLoading(true);

    try {
      const res = await createStreamSession();
      if (res.status === "success") {
        setFaceLivenessSessionId(res.data.SessionId);
        setIsStarted(true);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const CustomError = useCallback(() => {
    return (
      <div className="flex items-center justify-center bg-red-100 text-red-600 border rounded-lg p-4 gap-4">
        <Text>{error?.error.message}</Text>
        <Button onClick={reset}>Try Again</Button>
      </div>
    );
  }, [error]);

  const reset = () => {
    setFaceLivenessSessionId(null);
    onFaceLivenessStart();
  };

  const onAnalysisComplete = async (sessionId) => {
    try {
      const res = await getLivenessSessionResult({
        id: id,
        sessionId: sessionId,
      });
      if (res.status === "success") {
        onCompareFaces();
      } else {
        onFail({
          failMessage: "Failed to verify face liveness. Please try again.",
        });
      }
    } catch (error) {
      onFail({
        failMessage: "Failed to verify face liveness. Please try again.",
      });
    }
  };

  const onCompareFaces = async () => {
    const res = await comparePassportProfileWithLivePerson({
      id: id,
    });

    if (res.status === "success") {
      onSuccess({
        profilePhotoBase64: res.data.profilePhotoBase64,
        liveFacePhotoBase64: res.data.liveFacePhotoBase64,
      });
    } else {
      onFail({
        failMessage: res.message,
        profilePhotoBase64: res.data.profilePhotoBase64,
        liveFacePhotoBase64: res.data.liveFacePhotoBase64,
      });
    }
  };

  return (
    <div className="min-h-screen p-4 flex items-center justify-center">
      <Card className="w-full max-w-md">
        {!isStarted ? (
          <Box className="text-center p-4">
            <Heading size="5">Face Liveness</Heading>
            <Text size={"2"}> Let's make sure nobody is impersonating you</Text>

            <div
              className="mt-8 flex items-center justify-center"
              style={{
                border: "2px dashed var(--gray-6)",
                borderRadius: "8px",
                padding: "2rem",
                minHeight: "200px",
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={onFaceLivenessStart}
            >
              <Camera size={48} color="var(--gray-6)" />
            </div>
          </Box>
        ) : loading ? (
          <Text>Loading. Please wait.</Text>
        ) : (
          <Box style={{ minHeight: "400px" }}>
            <FaceLivenessDetector
              disableStartScreen={true}
              sessionId={faceLivenessSessionId}
              region="ap-northeast-1"
              onAnalysisComplete={() => onAnalysisComplete(faceLivenessSessionId)}
              onError={(error) => setError(error)}
              onUserCancel={() => {
                setFaceLivenessSessionId();
                setIsStarted(false);
              }}
              components={{
                ErrorView: CustomError,
              }}
            />
          </Box>
        )}
      </Card>
    </div>
  );
};

export default Liveness;
