import React, { useState, useEffect } from "react";
import EnterData from "./components/EnterData";
import Guide from "./components/Guide";
import Upload from "./components/Upload";
import Liveness from "./components/Liveness";
import Success from "./components/Success";
import { usePassportService } from "./usePassportService";
import FailVerifyPassport from "./components/FailVerifyPassport";
import FailVerifyLivess from "./components/FailVerifyLiveness";
import { useLocation } from "react-router-dom";
import ExpiredOrInvalidLink from "../ekyc/components/ExpiredOrInvalidLink";

export const EKYCPassportContext = React.createContext();

const Passport = () => {
  const { start, verify } = usePassportService();

  const [id, setId] = useState(null);
  const [step, setStep] = useState("enterData");
  const [failMessage, setFailMessage] = useState(null);
  const [passportBase64, setPassportBase64] = useState(null);
  const [profilePhotoBase64, setProfilePhotoBase64] = useState(null);
  const [liveFacePhotoBase64, setLiveFacePhotoBase64] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [reasonAndSolutionList, setReasonAndSolutionList] = useState([]);
  const [errorCode, setErrorCode] = useState(null);
  const [redirectURL, setRedirectURL] = useState(null);
  const location = useLocation();
  const { getValidationRecordFromToken } = usePassportService();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");

    if (token) {
      getValidationRecordFromToken(token)
        .then((data) => {
          console.log(data);
          if (data.status === "success") {

            const redirectUrl = data.data.RedirectURL;

            if (data.data.StepTwoResult === "Pass" && redirectUrl) {
              window.location.href = redirectUrl;
              return;
            }

            setRedirectURL(redirectUrl);
            setId(data.data._default + "." + data.data._id);

            if (data.data.StepOneResult === undefined || data.data.StepOneResult === "Fail") {
              setStep("guide");
            } else if (
              data.data.StepOneResult === "Pass" &&
              (data.data.StepTwoResult === "Fail" || data.data.StepTwoResult === undefined)
            ) {
              setStep("liveness");
            }
          } else {
            setErrorCode(data.errorCode);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          alert("An error occurred while processing the link");
        });
    }
  }, [location, getValidationRecordFromToken]);

  if (errorCode) {
    return <ExpiredOrInvalidLink errorCode={errorCode} />; // Render the ExpiredOrInvalidLink component if expired
  }

  const onSubmit = (data) => {
    start(data).then((res) => {
      if (res.status === "success") {
        setId(res.data.sessionId);
        setStep("guide");
      }
    });
  };

  const handleVerify = async () => {
    setIsSubmitting(true);
    verify({
      id: id,
    })
      .then((res) => {
        if (res.status === "success") {
          setStep("liveness");
          setFailMessage(null);
          setPassportBase64(null);
        } else {
          setStep("failVerifyPassport");
          setFailMessage(res.message);
          setPassportBase64(res.data.PassportBase64);
          setReasonAndSolutionList(res.data.ReasonAndSolutionList);
        }
        setIsSubmitting(false);
      })
      .catch(() => {
        setIsSubmitting(false);
      });
  };

  const handleComplete = ({ profilePhotoBase64, liveFacePhotoBase64 }) => {
    setProfilePhotoBase64(profilePhotoBase64);
    setLiveFacePhotoBase64(liveFacePhotoBase64);
    setFailMessage(null);
    setStep("success");
    if (redirectURL) {
      window.open(redirectURL, "_self");
    }
  };

  const renderSteps = () => {
    switch (step) {
      case "enterData":
        return <EnterData onNext={onSubmit} />;
      case "guide":
        return <Guide onNext={() => setStep("upload")} />;
      case "upload":
        return <Upload isSubmitting={isSubmitting} onSubmit={handleVerify} />;
      case "liveness":
        return (
          <Liveness
            onSuccess={handleComplete}
            onFail={(result) => {
              setStep("failVerifyLiveness");
              setFailMessage(result.failMessage);
            }}
          />
        );
      case "success":
        return (
          <Success
            profilePhotoBase64={profilePhotoBase64}
            liveFacePhotoBase64={liveFacePhotoBase64}
            onStartAgain={() => {
              setStep("enterData");
              setId(null);
              setProfilePhotoBase64(null);
              setLiveFacePhotoBase64(null);
            }}
          />
        );
      case "failVerifyPassport":
        return (
          <FailVerifyPassport
            base64Image={passportBase64}
            failMessage={failMessage}
            onRetry={() => {
              setStep("upload");
              setFailMessage(null);
              setProfilePhotoBase64(null);
              setLiveFacePhotoBase64(null);
            }}
            reasonAndSolutionList={reasonAndSolutionList}
          />
        );
      case "failVerifyLiveness":
        return (
          <FailVerifyLivess
            profilePhotoBase64={profilePhotoBase64}
            liveFacePhotoBase64={liveFacePhotoBase64}
            onRetry={() => {
              setStep("liveness");
              setProfilePhotoBase64(null);
              setLiveFacePhotoBase64(null);
            }}
          />
        );
      default:
        return <EnterData onSubmit={onSubmit} />;
    }
  };

  return (
    <EKYCPassportContext.Provider
      value={{
        id,
      }}
    >
      <div className="bg-gray-100">{renderSteps()}</div>
    </EKYCPassportContext.Provider>
  );
};

export default Passport;
