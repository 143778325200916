import React, { useState, useContext, useRef, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonBase from "@mui/material/ButtonBase";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import CancelIcon from "@mui/icons-material/Cancel";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { EKYCContext } from "../EKYC3";
import { AspectRatio } from "react-aspect-ratio";
import { useMyKadService } from "../services/useMyKadService";
import ImageProcessDialog from "./ImageProcessDialog";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InfoIcon from "@mui/icons-material/Info";
import GoodGuideline from "../../../assets/images/icguideline-good.jpg";
import BadGuideline from "../../../assets/images/icguideline-badCropped.jpg";
import { useMyTenteraService } from "../services/useMyTenteraService";
import MaxRetriesExceeded from "./MaxRetriesExceeded";

const VerifyMyKad = (props) => {
  const { onNext } = props;

  const myKadFrontInputRef = useRef(null);
  const myKadBackInputRef = useRef(null);

  const { id, docType, verifyMyKadResult, setVerifyMyKadResult } = useContext(EKYCContext);

  const { verifyMyKad3 } = useMyKadService();
  const { verifyKad } = useMyTenteraService();

  const [myKadFrontBase64, setMyKadFrontBase64] = useState(null);
  const [myKadBackBase64, setMyKadBackBase64] = useState(null);

  const [submitting, setSubmitting] = useState(false);
  const [submittingMessage, setSubmittingMessage] = useState("Uploading...");
  const [failVerification, setFailVerification] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [possibleFailReasonList, setPossibleFailReasonList] = useState([]);

  const [isProcessingImage, setIsProcessingImage] = useState(false);
  const [processingFile, setProcessingFile] = useState(null);
  const [sourceOrigin, setSourceOrigin] = useState();
  const [myKadSide, setMyKadSide] = useState("");

  const [isMobile, setIsMobile] = useState(false);
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  const [retryCount, setRetryCount] = useState(() => {
    const storedCount = localStorage.getItem(`retryCount_${id}`);
    return storedCount ? parseInt(storedCount, 0) : 0;
  });
  const MAX_RETRIES = 3;

  const [showMaxRetriesPage, setShowMaxRetriesPage] = useState(false);

  useEffect(() => {
    const mobilePatterns = [/android/i, /iPad|iPhone|iPod/i, /windows phone/i, /blackberry/i, /opera mini/i, /mobile/i];
    const isMobileDevice = mobilePatterns.some((pattern) => pattern.test(userAgent));
    setIsMobile(isMobileDevice);
  }, []);

  useEffect(() => {
    if (!localStorage.getItem(`retryCount_${id}`)) {
      localStorage.setItem(`retryCount_${id}`, "0");
    }
  }, [id]);

  useEffect(() => {
    const currentRetries = parseInt(localStorage.getItem(`retryCount_${id}`) || "0", 10);
    if (currentRetries >= MAX_RETRIES) {
      setShowMaxRetriesPage(true);
    }
  }, [id]);

  const onSubmit = async () => {
    setSubmitting(true);
    setSubmittingMessage("Verifying...");

    try {
      const response =
        docType === "MyKad"
          ? await verifyMyKad3({
              id: id,
            })
          : await verifyKad({
              id: id,
            });
      if (response.status === "success") {
        setFailVerification(false);
        setVerifyMyKadResult(response.data);
        localStorage.removeItem(`retryCount_${id}`);
        onNext();
      } else {
        setFailVerification(true);
        setErrorMessage(response.message);
        setPossibleFailReasonList(response.data.FailReasonAndSolutionList);
        setVerifyMyKadResult(response.data);
        const newCount = parseInt(localStorage.getItem(`retryCount_${id}`) || "0", 10) + 1;
        localStorage.setItem(`retryCount_${id}`, newCount.toString());
        setRetryCount(newCount);
        if (newCount >= MAX_RETRIES) {
          setShowMaxRetriesPage(true);
        }
      }
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      setRetryCount((prevCount) => prevCount + 1);
    }
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRetry = () => {
    const currentRetries = parseInt(localStorage.getItem(`retryCount_${id}`) || "0", 10);
    if (currentRetries >= MAX_RETRIES) {
      setShowMaxRetriesPage(true);
      return;
    }

    setMyKadFrontBase64(null);
    setMyKadBackBase64(null);
    setFailVerification(false);
    setErrorMessage(null);
  };

  if (showMaxRetriesPage) {
    return <MaxRetriesExceeded />;
  }

  return (
    <Box>
      <Backdrop
        open={submitting}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Stack alignItems={"center"} justifyContent={"center"}>
          <CircularProgress
            style={{
              color: "rgba(255, 255, 255)",
            }}
          />
          <Typography variant="h6" sx={{ color: "#fff" }}>
            {submittingMessage}
          </Typography>
        </Stack>
      </Backdrop>

      {failVerification ? (
        <Box p={2}>
          <Box textAlign={"center"}>
            <Box my={2}>
              <CancelIcon htmlColor="#F1612E" style={{ fontSize: 72 }} />
              <Typography fontWeight={"bold"} fontSize={24}>
                {errorMessage}
              </Typography>
            </Box>
            <Typography variant="body1">Please refer to the details below for the solution.</Typography>
          </Box>
          <Box px={2} mt={4}>
            <Box textAlign={"center"} bgcolor={"#CFC7CA"} p={2}>
              <Box>
                <Typography fontWeight="bold" my={2}>
                  Front MyKad
                </Typography>

                <Box border={"2px solid #000"}>
                  <img
                    src={"data:image/jpeg;base64," + myKadFrontBase64}
                    alt="MyKad Front"
                    width={"100%"}
                    height={"auto"}
                  />
                </Box>
                <Typography my={2} mb={1}>
                  {verifyMyKadResult?.["CustomerName"]}
                </Typography>
                <Typography>{verifyMyKadResult?.["NRICFront"]}</Typography>
              </Box>
              <Divider sx={{ marginTop: 4, marginBottom: 4 }} />
              <Box>
                <Typography fontWeight="bold" my={2}>
                  Back MyKad
                </Typography>
                <Box border={"2px solid #000"}>
                  <img
                    src={"data:image/jpeg;base64," + myKadBackBase64}
                    alt="MyKad Back"
                    width={"100%"}
                    height={"auto"}
                  />
                </Box>
                <Typography my={2}>{verifyMyKadResult?.["NRICBack"]}</Typography>
              </Box>
            </Box>
            <Grid container>
              <Grid item xs={6} border={"1px solid #000"} p={1}>
                <Typography fontWeight="bold">Possible Reasons </Typography>
              </Grid>
              <Grid item xs={6} border={"1px solid #000"} borderLeft={"none"} p={1}>
                <Typography fontWeight="bold">Solution </Typography>
              </Grid>
              {possibleFailReasonList.map((item, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={6} border={"1px solid #000"} p={1}>
                    {item.reason}
                  </Grid>
                  <Grid item xs={6} border={"1px solid #000"} borderLeft={"none"} p={1}>
                    {item.solution}
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Box>

          <Box textAlign={"center"} mt={4}>
            {retryCount >= MAX_RETRIES ? (
              <Button
                variant="contained"
                onClick={() => setShowMaxRetriesPage(true)}
                sx={{
                  backgroundColor: "#B41E8E",
                  minWidth: "120px",
                  borderColor: "#000",
                }}
              >
                CLOSE
              </Button>
            ) : (
              <>
                <Typography mb={2}>Attempts remaining: {MAX_RETRIES - retryCount}</Typography>
                <Button
                  variant="contained"
                  onClick={handleRetry}
                  sx={{
                    backgroundColor: "#B41E8E",
                    minWidth: "120px",
                    borderColor: "#000",
                  }}
                >
                  RETRY
                </Button>
              </>
            )}
          </Box>
        </Box>
      ) : (
        <Grid container justifyContent={"center"} p={4}>
          <Grid item xs={12} md={4}>
            <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} margin={2}>
              <Typography variant="h6" fontWeight={"bold"} textAlign={"center"} my={1}>
                Take a picture of your IC
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClickOpen}
                startIcon={<InfoIcon />}
                size="small"
              >
                HINT
              </Button>
            </Box>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
              fullWidth
            >
              <DialogTitle id="alert-dialog-title">{"Picture Taking Guidelines"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  To ensure a smooth process, please follow these guidelines when taking pictures of your IC:
                  <ul className="list-disc list-inside mt-2 space-y-1">
                    <li>Ensure good lighting conditions</li>
                    <li>Place the IC on a flat, contrasting surface</li>
                    <li>Capture the entire IC in the frame</li>
                    <li>Avoid glare or reflections on the IC</li>
                    <li>Make sure the image is clear and in focus</li>
                    <li>Ensure the image's four corners align with the edges of the box</li>
                  </ul>
                </DialogContentText>

                <div className="mt-4">
                  <h3 className="font-bold text-lg">Correct Example:</h3>
                  <img
                    src={GoodGuideline}
                    alt="Good IC Cropped Example"
                    style={{ width: "100%", height: "auto", objectFit: "contain" }} // Ensures it fits the dialog
                  />

                  <h3 className="font-bold text-lg mt-4">Incorrect Example:</h3>
                  <img
                    src={BadGuideline}
                    alt="Bad IC Cropped Example"
                    style={{ width: "100%", height: "auto", objectFit: "contain" }} // Ensures it fits the dialog
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                  Close
                </Button>
              </DialogActions>
            </Dialog>

            <Stack spacing={2} mb={2}>
              <Box>
                <ButtonBase
                  style={{
                    border: "1px solid #B41E8E",
                  }}
                  sx={{ width: "100%" }}
                  onClick={() => {
                    myKadFrontInputRef.current.click();
                  }}
                >
                  <AspectRatio
                    ratio={"3/4"}
                    style={{
                      width: "100%",
                      minHeight: "180px",
                      height: "auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {myKadFrontBase64 ? (
                      <img
                        src={"data:image/jpeg;base64," + myKadFrontBase64}
                        alt="MyKad Front"
                        width={"100%"}
                        height={"auto"}
                      />
                    ) : (
                      <>
                        <PhotoCameraIcon fontSize={"large"} htmlColor="#B41E8E" />
                        <Typography variant="body1">Front IC</Typography>
                      </>
                    )}

                    <input
                      ref={myKadFrontInputRef}
                      accept="image/*"
                      id="mykad-front"
                      type="file"
                      capture="environment"
                      hidden
                      onChange={async (e) => {
                        const file = e.target.files[0];
                        setProcessingFile(file);
                        !isMobile ? setSourceOrigin() : setSourceOrigin("scanbot");
                        setMyKadSide("front");
                        setIsProcessingImage(true);
                        // reset the inputRef
                        myKadFrontInputRef.current.value = null;
                      }}
                    />
                  </AspectRatio>
                </ButtonBase>

                {myKadFrontBase64 && (
                  <>
                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        justifyContent: "center",
                        gap: 1,
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                          myKadFrontInputRef.current.click();
                        }}
                      >
                        Retake
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
              <Box>
                <ButtonBase
                  style={{
                    border: "1px solid #B41E8E",
                  }}
                  sx={{ width: "100%" }}
                  onClick={() => {
                    myKadBackInputRef.current.click();
                  }}
                >
                  <AspectRatio
                    ratio={"3/4"}
                    style={{
                      width: "100%",
                      minHeight: "180px",
                      height: "auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {myKadBackBase64 ? (
                      <img
                        src={"data:image/jpeg;base64," + myKadBackBase64}
                        alt="MyKad Back"
                        width={"100%"}
                        height={"auto"}
                      />
                    ) : (
                      <>
                        <PhotoCameraIcon fontSize={"large"} htmlColor="#B41E8E" />
                        <Typography variant="body1">Back IC</Typography>
                      </>
                    )}
                    <input
                      ref={myKadBackInputRef}
                      accept="image/*"
                      id="mykad-back"
                      type="file"
                      capture="environment"
                      hidden
                      onChange={async (e) => {
                        const file = e.target.files[0];
                        setProcessingFile(file);
                        !isMobile ? setSourceOrigin() : setSourceOrigin("scanbot");
                        setMyKadSide("back");
                        setIsProcessingImage(true);
                        // reset the inputRef
                        myKadBackInputRef.current.value = null;
                      }}
                    />
                  </AspectRatio>
                </ButtonBase>
                {myKadBackBase64 && (
                  <>
                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        justifyContent: "center",
                        gap: 1,
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                          myKadBackInputRef.current.click();
                        }}
                      >
                        Retake
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            </Stack>

            <Box textAlign={"center"}>
              <LoadingButton
                sx={{
                  backgroundColor: "#B41E8E",
                  minWidth: "120px",
                  borderColor: "#000",
                }}
                variant="contained"
                onClick={onSubmit}
                loading={false}
                disabled={!myKadFrontBase64 || !myKadBackBase64}
              >
                <span>CONFIRM</span>
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      )}

      <ImageProcessDialog
        id={id}
        docType={docType}
        visible={isProcessingImage}
        uploadFile={processingFile}
        myKadSide={myKadSide}
        sourceOrigin={sourceOrigin}
        onSuccess={(base64) => {
          if (myKadSide === "back") {
            setMyKadBackBase64(base64);
          } else {
            setMyKadFrontBase64(base64);
          }
          setIsProcessingImage(false);
        }}
        onError={(message) => {
          setIsProcessingImage(false);
          alert(message);
        }}
        onClose={() => {
          setIsProcessingImage(false);
        }}
      />
    </Box>
  );
};

export default VerifyMyKad;
