import React from "react";
import { Button, Card, Table } from "@radix-ui/themes";

const FailVerifyPassport = ({ base64Image, failMessage, onRetry, reasonAndSolutionList }) => {
  return (
    <div className="min-h-screen p-4 flex items-center justify-center">
      <Card className="w-full max-w-md">
        <div className="text-center">
          <h1 className="text-2xl font-semibold text-red-800">Verification Fail</h1>
          <p className="text-gray-600 mt-2">{failMessage}</p>
        </div>

        <div className="flex items-center justify-center my-6">
          <div className="w-full">
            <img
              src={"data:image/jpeg;base64," + base64Image}
              width={"auto"}
              height={200}
              className="rounded-lg object-contain w-full h-48"
            />
          </div>
        </div>

        <div className="mt-6">
          <Table.Root>
            <Table.Header>
              <Table.Row>
                <Table.ColumnHeaderCell>Possible Reason</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Suggested Resolution</Table.ColumnHeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {reasonAndSolutionList.map((reasonAndSolution, index) => (
                <Table.Row key={index}>
                  <Table.Cell>{reasonAndSolution.Reason}</Table.Cell>
                  <Table.Cell>{reasonAndSolution.Resolution}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table.Root>
        </div>

        <div className="flex justify-center mt-6">
          <Button className="w-full" onClick={onRetry}>
            Retry
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default FailVerifyPassport;
