import { useCallback, useContext } from "react";
import { AuthContext } from "../../../App";
import { SERVER_HOST } from "../../../constants/config";

const BaseAPIUrl = SERVER_HOST;

const useDocumentLivenessService = () => {
  const { authState } = useContext(AuthContext);

  const verify = useCallback(
    async (params) => {
      const config = {
        headers: {
          Authorization: "Bearer " + authState.token,
        },
        method: "post",
        body: params,
      };

      return fetch(BaseAPIUrl + "/documentLiveness/verifyPhoto", config).then((response) => response.json());
    },
    [authState]
  );
 
  const verifyVideo = useCallback(
    async (params) => {
      const config = {
        headers: {
          Authorization: "Bearer " + authState.token,
        },
        method: "post",
        body: params,
      };

      return fetch(BaseAPIUrl + "/documentLiveness/verifyVideo", config).then((response) => response.json());
    },
    [authState]
  );

  return {
    verify,
    verifyVideo,
  };
};

export { useDocumentLivenessService };
